import { styled } from '@linaria/react'
import { useTranslation } from 'react-i18next'

import barricade from '../../assets/common/barricade.webp'
import { CustomerServiceButton } from './customer-service'

const Container = styled.div`
  height: 100vh;
  background-color: var(--primary);
  padding: 0 20px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
`

const Barricade = styled.img`
  width: 100%;
  max-width: 600px;
`

const Oops = styled.h2`
  font-size: 50px;
  font-family: 'Geometr706-BlkCn-BT';
  color: var(--txt);
  margin-bottom: 12px;
  text-transform: uppercase;
  @media (min-width: 1200px) {
    font-size: 60px;
  }
`

const Message = styled.h3`
  font-size: 16px;
  font-family: 'Syncopate-Bold';
  color: #46aef7;
  margin-bottom: 40px;
  text-transform: uppercase;
  text-align: center;
  max-width: 600px;
  @media (min-width: 1200px) {
    font-size: 24px;
  }
`

const Sign = styled.p`
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Syncopate-Bold';
  font-size: 18px;
  color: #0e091e;
  @media (min-width: 1200px) {
    font-size: 30px;
  }
`

const CustomerWrapper = styled.div`
  position: fixed;
  bottom: 70px;
  right: 10px;
  @media (min-width: 1200px) {
    bottom: 20px;
    right: 20px;
  }
`

const ErrorMessage = styled.p`
  text-align: center;
  color: #697172;
  font-weight: 500;
  font-size: 14px;
  margin-top: 24px;
  text-transform: uppercase;
`

interface ErrorPageProps {
  type?: '404'
  errorMessage?: string
}

const ErrorPage = ({ type, errorMessage }: ErrorPageProps) => {
  const { t } = useTranslation('common')

  return (
    <Container className="v-flx-ctr">
      <Oops>OOPS!</Oops>
      <Message>{t('something-went-wrong')}</Message>
      <div style={{ maxWidth: 600, position: 'relative' }}>
        {type && <Sign>{type}</Sign>}
        <Barricade src={barricade} alt="Barricade" />
        {errorMessage && <ErrorMessage>ERROR: {errorMessage}</ErrorMessage>}
      </div>
      {!window.ReactNativeWebView && (
        <CustomerWrapper onClick={() => window.fcWidget?.open()}>
          <CustomerServiceButton />
        </CustomerWrapper>
      )}
    </Container>
  )
}

export default ErrorPage
